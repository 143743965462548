import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css'

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // const formData = new FormData();
        // formData.append('username', username);
        // formData.append('password', password);
        // console.log('form', username, password, formData)

        try {
            const response = await axios.post('https://bi.cdgcairo.com/login.php',{
                username,
                password
            });
            console.log(response)
            if (response.data.success) {
                localStorage.setItem('token', response.data.token);
                navigate('/')
            } else {
                setError('Invalid username or password');
            }
        } catch (err) {
            console.error('Error logging in:', err);
            setError('An error occurred during login.');
        }
    };

    return (
        <div className="login-container">
            <h1>Admin Login</h1>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
