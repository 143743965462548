import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TeamList.css';
import Navbar from './Navbar';
const token = localStorage.getItem('token');


function TeamList() {
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        fetchTeamMembers();
    }, []);

    const fetchTeamMembers = async () => {
        try {
            const response = await axios.get('https://bi.cdgcairo.com/get_team_members.php',{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log(response)
            setTeamMembers(response.data);
        } catch (error) {
            console.error('Error fetching team members:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://bi.cdgcairo.com/delete_team_member.php?id=${id}`,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            fetchTeamMembers();
        } catch (error) {
            console.error('Error deleting team member:', error);
        }
    };

    return (
        <>
        <Navbar/>
        <div className="team-list">
            <h2>Team Members</h2>
            <ul>
                {teamMembers.map(member => (
                    <li key={member.id}>
                        <img src={`https://bi.cdgcairo.com/uploads/${member.image}`} alt={member.name} />
                        <span>{member.name}</span>
                        <button onClick={() => handleDelete(member.id)}>Delete</button>
                        <a href={`/edit/${member.id}`}>Edit</a>
                    </li>
                ))}
            </ul>
        </div>
        </>
    );
}

export default TeamList;
