import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const navigate = useNavigate();
    const handleLogout = () => {
        // Clear token from local storage or any authentication storage
        localStorage.removeItem('token');

        // Redirect to login page
        navigate('/login');
    };
    return (
        <nav className="navbar">
            <div className="navbar-container">
                <h1 className="navbar-logo">Admin Panel</h1>
                <ul className="navbar-menu">
                    <li className="navbar-item">
                        <Link to="/add" className="navbar-link">Add Team Member</Link>
                    </li>
                    <li className="navbar-item">
                        <Link to="/" className="navbar-link">View Team Members</Link>
                    </li>
                    <li className="navbar-item">
                        <Link to="/order" className="navbar-link">Order Team Members</Link>
                    </li>
                    <li className="navbar-item">
                        <button onClick={handleLogout} style={{'color':'#333', 'cursor':'pointer'}} className="navbar-link">Logout</button>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
