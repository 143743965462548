// auth.js (or a utility file)
import { jwtDecode }  from 'jwt-decode';

export const isTokenExpired = () => {
    const token = localStorage.getItem('token');

    if (!token) {
        return true;
    }

    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        // Check if the token has expired
        if (decoded.exp < currentTime) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return true;
    }
};
