import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import AddTeamMember from './AddTeamMember';
import TeamList from './TeamList';
import EditTeamMember from './EditTeamMember';
import OrderTeamMembers from './OrderTeamMembers';
import ProtectedRoute from './ProtectedRoute';
import Login from './Login';


function App() {
    return (
        <BrowserRouter>
            <div className="App">
                
                <div className="content"></div>
                  <Routes>
                      <Route path="/login" element={<Login/>} />
                      <Route exact path='/' element={<ProtectedRoute/>}>
                            <Route exact path='/' element={<TeamList/>}/>
                    </Route>
                    <Route exact path="/add" element={<ProtectedRoute/>}>
                            <Route exact path='/add' element={<AddTeamMember/>}/>
                    </Route>
                    <Route exact path='/edit/:id' element={<ProtectedRoute/>}>
                            <Route exact path='/edit/:id' element={<EditTeamMember/>}/>
                    </Route>
                    <Route exact path='/order' element={<ProtectedRoute/>}>
                            <Route exact path='/order' element={<OrderTeamMembers/>}/>
                    </Route>
                  </Routes>
                </div>
        </BrowserRouter>
    );
}

export default App;
