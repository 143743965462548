import React, { useState } from 'react';
import axios from 'axios';
import './AddTeamMember.css';
import Navbar from './Navbar';
const token = localStorage.getItem('token');


function AddTeamMember() {
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('image', image);
        console.log('test', name, image)
        console.log('form', formData)
        try {
            //https://bi.cdgcairo.com/add_team_member.php
            //http://localhost/cdgbackend/add_team_member.php
            const response = await axios.post('https://bi.cdgcairo.com/add_team_member.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });
            console.log('res', response)
            setName('')
            setImage(null)
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Error: ' + (error.response ? error.response.data.error : 'Server error'));
        }
    };

    return (
        <>
        <Navbar/>
        <div className="add-team-member">
            <h2>Add New Team Member</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={handleNameChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="image">Upload Image:</label>
                    <input
                        type="file"
                        id="image"
                        accept="image/*"
                        onChange={handleImageChange}
                        required
                    />
                </div>
                <button type="submit">Add Member</button>
            </form>
            {message && <p>{message}</p>}
        </div>
        </>

    );
}

export default AddTeamMember;
