import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './EditTeamMember.css';
import Navbar from './Navbar';
const token = localStorage.getItem('token');


function EditTeamMember() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchTeamMember();
    }, []);

    const fetchTeamMember = async () => {
        try {
            const response = await axios.get(`https://bi.cdgcairo.com/get_team_member.php?id=${id}`,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setName(response.data.name);
            // Assuming you handle image display differently or do not update it
        } catch (error) {
            console.error('Error fetching team member:', error);
        }
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        if (image) formData.append('image', image);
        console.log('form', formData)
        try {
            const response = await axios.post(`https://bi.cdgcairo.com/update_team_member.php?id=${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });
            console.log('response', response)
            setMessage('Team member updated successfully');
            navigate('/')
        } catch (error) {
            setMessage('Error: ' + (error.response ? error.response.data.error : 'Server error'));
        }
    };

    return (
        <>
        <Navbar/>
        <div className="edit-team-member">
            <h2>Edit Team Member</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={handleNameChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="image">Upload New Image (optional):</label>
                    <input
                        type="file"
                        id="image"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                </div>
                <button type="submit">Update Member</button>
            </form>
            {message && <p>{message}</p>}
        </div>
        </>
    );
}

export default EditTeamMember;
