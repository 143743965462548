import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OrderTeamMembers.css';
import Navbar from './Navbar';
const token = localStorage.getItem('token');


function OrderTeamMembers() {
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        fetchTeamMembers();
    }, []);

    const fetchTeamMembers = async () => {
        try {
            const response = await axios.get('https://bi.cdgcairo.com/get_team_members.php',{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setTeamMembers(response.data);
        } catch (error) {
            console.error('Error fetching team members:', error);
        }
    };

    const handleOrderChange = (event, index) => {

        const newOrder = [...teamMembers];
        newOrder.splice(index, 1);
        newOrder.splice(event.target.value, 0, teamMembers[index]);
        setTeamMembers(newOrder);
    };

    const handleSaveOrder = async () => {
        try {
            const test = teamMembers.map(member => member.id);
            const formData = new FormData();
            formData.append('order', test);
            const response = await axios.post('https://bi.cdgcairo.com/update_team_member_order.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
        });
        alert('Order updated successfully');
            console.log('Order updated successfully', response);
        } catch (error) {
            console.error('Error saving order:', error);
        }
    };

    return (
        <>
        <Navbar/>
        <div className="order-team-members">
            <h2>Order Team Members</h2>
            <ul>
                {teamMembers.map((member, index) => (
                    <li key={member.id}>
                        <span>{member.name}</span>
                        <select
                            value={teamMembers.indexOf(member)}
                            onChange={(e) => handleOrderChange(e, index)}
                        >
                            {teamMembers.map((_, i) => (
                                <option key={i} value={i}>{i + 1}</option>
                            ))}
                        </select>
                    </li>
                ))}
            </ul>
            <button onClick={handleSaveOrder}>Save Order</button>
        </div>
        </>
    );
}

export default OrderTeamMembers;
