import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isTokenExpired } from './auth';

function ProtectedRoute({ component: Component, ...rest }) {

    return localStorage.getItem('token') || !isTokenExpired() ? <Outlet /> : <Navigate to="/login" />;
    
}

export default ProtectedRoute;
